.ccPageContainer {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;

    height: 100vh;
}

.ccPDF {
    width: 100%;
    height: 100%;

    text-align: center;
}

.ccContainer {
    display: none;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 0.5% 0 0.5% 0;

    font-family: Aaux ProRegular;
}
.ccContainer>p {
    font-size: calc(1 * (1vw + 1vh - 1vmin));
    font-size: 1vmax;

    padding: 0 2% 0 0;

    color: var(--pri-color);
}

.ccDownloadButton{
    font-family: Aaux ProRegular;
    font-size: calc(1.1 * (1vw + 1vh - 1vmin));
    font-size: 1.1vmax;

    color: var(--sec-color);
    background: var(--pri-color);

    padding: 0.5% 2% 0.5% 2%;

    -moz-border-radius: calc(1.5 * (1vw + 1vh - 1vmin));
    -webkit-border-radius: calc(1.5 * (1vw + 1vh - 1vmin));
    border-radius: calc(1.5 * (1vw + 1vh - 1vmin));
    border-radius: 1.5vmax;

    cursor: pointer;

    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}
.ccDownloadButton:hover {
    background: var(--tri-color);
}

@media only screen and (max-width: 800px) {
    .ccContainer {
        background: var(--sec-color);

        height: 100vh;
        flex-direction: column;

        padding: 0;
    }
    .ccContainer>p {
        font-size: calc(2.4 * (1vw + 1vh - 1vmin));
        font-size: 2.4vmax;
    
        padding: 2% 0 2% 0;
    }
    .ccDownloadButton{
        font-size: calc(2.4 * (1vw + 1vh - 1vmin));
        font-size: 2.4vmax;
    
        padding: 1.5% 6% 1.5% 6%;
    
        -moz-border-radius: calc(2.8 * (1vw + 1vh - 1vmin));
        -webkit-border-radius: calc(2.8 * (1vw + 1vh - 1vmin));
        border-radius: calc(2.8 * (1vw + 1vh - 1vmin));
        border-radius: 2.8vmax;
    }
}