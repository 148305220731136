@font-face {
  font-family: Aaux ProRegular;
  src: url('./Fonts/Aaux-ProRegular-Regular.ttf');
}

:root {
  --pri-color: white;
  --sec-color: black;
  --sec-color: #181818;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}